<template>
  <div class="testPage" id="testPage" @click="showTip = false">
    <audio ref="audio" controls :src="currentProblem[sound + 'fy']" style="width: 0;height: 0;position: absolute;top: 0;">您的浏览器不支持 audio 标签。</audio>
    <studyHeader @back="back" />
    <div class="studyBox">
      <div class="main studyMain" v-if="content !== 'test'">
        <!-- 头部 -->
        <div class="unitsTitle">
          <img src="../../../assets/images/student/unitsTitle.png" alt="">
          <div class="text">开始测试</div>
        </div>
        <div class="progress">
          <el-progress :text-inside="true" :stroke-width="16" :percentage="submitData.problem ? ((currentIndex + 1) / submitData.problem.length * 100) : 0"></el-progress>
          <div class="text">{{ submitData.problem ? (currentIndex + 1 + '/' + submitData.problem.length) : 0 }}</div>
        </div>
        <!-- 英选汉 -->
        <div class="test1" v-if="content == 'test1'">
          <div class="header">
            <div class="title">请选择正确的词义</div>
            <p class="time"> 剩余 <span>{{ time }}</span> 秒 </p>
          </div>
          <div class="wordaudio">
            <div class="word"> {{ currentProblem.nr }}
            </div>
          </div>
          <div class="options">
            <div class="option" v-for="item in currentProblem.options" :key="item">
              <div class="answer" :style="ansYes === item ? 'background: #8BDF1F;color: #fff;' : (ansNo === item ? 'background: #FF4545;color: #fff;' : '')" @click="doTest('1', item,$event)">
                {{ item }}
                <!-- 正确 -->
                <div class="icon" v-if="ansYes === item">
                  <img src="../../../assets/images/student/right.png" alt="">
                </div>
                <!-- 错误 -->
                <div class="icon" v-if="ansNo === item">
                  <img src="../../../assets/images/student/Wrong.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 拼词 -->
        <div class="test2" v-if="content == 'test2'">
          <!-- 头 -->
          <div class="header">
            <div class="title">看义拼词</div>
            <p class="time"> 剩余 <span>{{ time }}</span> 秒 </p>
          </div>
          <!-- 词义 -->
          <div class="mean">
            <span @click.stop="showAll(currentProblem.cy)">{{ currentProblem.cy }}</span>
          </div>
          <div class="options2">
            <div class="center">
              <div v-for="data in optionSelected" :key="data.txt" class="option2">{{ data.txt }}</div>
              <div class="del" @click="delAll" :style="optionSelected.length > 0 ? 'display:block;' : 'display:none;'"><i class="el-icon-circle-close"></i></div>
            </div>
          </div>
          <hr class="hr">
          <div class="answers2">
            <div class="answer2" :class="{ selected: optionSelected.map((ele) => {return ele.index }).indexOf(index) !== -1 }" v-for="(data, index) in currentProblem.options" :key="data" @click="selected(data, index)">
              {{ data }}
            </div>
          </div>
          <div class="btns" @click="submit($event)">
            <img src="../../../assets/images/student/cooked.png" alt="">
            <span class="text">提交</span>
          </div>
        </div>
        <!-- 汉译英 -->
        <div class="test1" v-if="content == 'test3'">
          <div class="header">
            <div class="title">请选择正确的单词</div>
            <p class="time"> 剩余 <span>{{ time }}</span> 秒 </p>
          </div>
          <div class="wordaudio">
            <!-- 词义 -->
            <div class="word wordhidden" @click.stop="showAll(currentProblem.cy)"> {{ currentProblem.cy }}
            </div>
          </div>
          <div class="options">
            <div class="option" v-for="item in currentProblem.options" :key="item">
              <div class="answer" :style="ansYes === item ? 'background: #8BDF1F;color: #fff;' : (ansNo === item ? 'background: #FF4545;color: #fff;' : '')" @click="doTest('3', item, $event)">
                {{ item }}
                <!-- 正确 -->
                <div class="icon" v-if="ansYes === item">
                  <img src="../../../assets/images/student/right.png" alt="">
                </div>
                <!-- 错误 -->
                <div class="icon" v-if="ansNo === item">
                  <img src="../../../assets/images/student/Wrong.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 音选英 -->
        <div class="test4" v-if="content == 'test4'">
          <div class="header">
            <div class="title">请选择正确的单词</div>
            <p class="time"> 剩余 <span>{{ time }}</span> 秒 </p>
          </div>
          <div class="wordaudio">
            <div class="img">
              <img src="../../../assets/images/student/horn.png" alt="" @click="play">
            </div>
          </div>
          <div class="options">
            <div class="option" v-for="item in currentProblem.options" :key="item">
              <div class="answer" :style="ansYes === item ? 'background: #8BDF1F;color: #fff;' : (ansNo === item ? 'background: #FF4545;color: #fff;' : '')" @click="doTest('4', item, $event)">
                {{ item }}
                <!-- 正确 -->
                <div class="icon" v-if="ansYes === item">
                  <img src="../../../assets/images/student/right.png" alt="">
                </div>
                <!-- 错误 -->
                <div class="icon" v-if="ansNo === item">
                  <img src="../../../assets/images/student/Wrong.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 开始测试 -->
      <div class="test" v-if="content == 'test'">
        <div class="congratulations">
          <div class="image">
            <img src="../../../assets/images/student/testcomplete.png" alt="">
            <!-- 分数展示 -->
            <em class="span"> {{ submitData.fs + '分' }} </em>
            <em class="text">
              本单元测试已完成!
            </em>
          </div>
        </div>
        <!-- 测试按钮 -->
        <div class="practice">
          <div class="right" @click="toUnits">
            <img src="../../../assets/images/student/rightbtn.png" alt="">
            <span class="rtext">完成</span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogMsg" class="dialogMsg">
      <div class="dio">
        <img src="../../../assets/images/student/no1.png" v-if="dioAnswer == 'no1'" alt="">
        <img src="../../../assets/images/student/yes1.png" v-if="dioAnswer == 'yes1'" alt="">
        <div class="title" v-if="dioAnswer === 'no1'">正确答案</div>
        <div class="dioWord">{{ currentProblem.nr }}</div>
      </div>
    </el-dialog>
    <!-- <div class="showall" id="showall" @click.stop="showTip = true" v-show="showTip"></div> -->
  </div>
</template>
<script>
import { submitWords, getPmzt, saveMyBreakpoint, getMyBreakpoint } from '@/api/student'
export default {
  components: {
    studyHeader: (resolve) => {
      require(["@/components/student/studyHeader"], resolve)
    }
  },
  data() {
    return {
      content: '', // test完成 // test1看义选英 // test3看义选英 // test4听音辨义 // test2第二轮测试
      sound: '', // 发音
      submitData: {}, // 学习阶段产生的数据汇总
      wordList: [], // 单词列表,
      currentProblem: {}, // 当前题目
      currentIndex: 0, // 当前题目位置
      time: 20, // 倒计时
      timer: null, // 计时器
      ansYes: '', // 正确答案展示
      ansNo: '', // 错误答案展示
      clickStop: false, // 防高频点击
      optionSelected: [], // 看义拼词答案
      optionAns: [], // 看义拼词选项
      dialogMsg: false,
      dioAnswer: '', // 对的穿yes1  错的传no1
      source: '', // 来源
      backPath: '', // 返回上一页的地址
      showTip: false, // 控制showAll显示隐藏--文字超出时点击显示
      key: 0
    }
  },
  created() {
    this.key = Math.random()
    sessionStorage.setItem('key', this.key)
    this.$Bus.$on('whether', (val) => {
      if (val) {
        clearInterval(this.timer)
        this.timer = null
      } else {
        this.timeStart(this.time)
      }
    })
    this.source = this.$route.query.source
    this.sound = localStorage.getItem('sound_' + this.$store.state.userInfo.xm)
    if (this.source === 'studyway') {
      this.backPath = '/wordcontent?type=' + this.$route.query.type + '&bbid=' + this.$route.query.bbid + '&zid=' + this.$route.query.zid + '&dyid=' + this.$route.query.dyid + '&source=' + this.$route.query.source+ '&bbmc=' + this.$route.query.bbmc
      getMyBreakpoint({ dyid:this.$route.query.dyid, xsid: this.$store.state.userInfo.yhid }).then(res => {
        if (res.success) {
          let notes = res.resultDesc === 'SUCCESS' ? '' : res.resultDesc
          if (notes) {
            this.submitData = JSON.parse(res.resultDesc)
            this.submitData.startTime = new Date().getTime()
            if (this.submitData.problemIndex !== undefined) {
              this.wordList = JSON.parse(sessionStorage.getItem('words'))
              this.currentIndex = this.submitData.problemIndex + 1 === this.submitData.problem.length ? this.submitData.problemIndex : (this.submitData.problemIndex + 1)
              this.currentProblem = this.submitData.problem[this.currentIndex]
              this.content = 'test' + this.currentProblem.type
              if (this.content != 'test3') {
                this.$nextTick(() => {
                  this.play()
                })
              }
              getPmzt({ bjid: JSON.parse(sessionStorage.getItem('jxInfo')).bjid }).then(e => {
                if (e.result) {
                  this.timeStart(20)
                }
              })
            } else {
              this.submitData.problem = []
              this.submitData.progress = 1
              this.submitData.Bingo = 0
              this.submitData.zwsl = 0
              //this.submitData.problemIndex = this.currentIndex
              // 制作考试题
              this.wordList = JSON.parse(sessionStorage.getItem('words'))
              this.makeProblom()
              this.currentProblem = this.submitData.problem[this.currentIndex]
              this.content = 'test' + this.currentProblem.type
              if (this.content != 'test3') {
                this.$nextTick(() => {
                  this.play()
                })
              }
              getPmzt({ bjid: JSON.parse(sessionStorage.getItem('jxInfo')).bjid }).then(e => {
                if (e.result) {
                  this.timeStart(20)
                }
              })
            }
          } else { // 测试完成，刷新，跳回units
            this.toUnits()
          }
        } else {
          this.$message.error(res.resultDesc)
        }
      }).catch(err => {
        this.$message.error({ message: '当前网络卡顿，请刷新后重试', duration: 0 })
      })
    } else {
      this.backPath = '/wordcontent?source=' + this.source
      this.submitData.problem = []
      this.submitData.Bingo = 0
      this.wordList = JSON.parse(sessionStorage.getItem('words'))
      this.makeProblom()
      this.currentProblem = this.submitData.problem[this.currentIndex]
      this.content = 'test' + this.currentProblem.type
      if (this.content != 'test3') {
        this.$nextTick(() => {
          this.play()
        })
      }
      this.timeStart(20)
    }
  },
  mounted() {
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  },
  methods: {
    // 题目超出时，点击展示全部
    showAll(val) {
      // var oDom = document.querySelector('.showall')
      // oDom.innerHTML = val
      this.showTip = !this.showTip
    },
    back() {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
      this.$router.replace({ path: this.backPath })
    },
    // 生成小虹心
    heartsFun(e) {
      // 获取点击坐标
      let x = e.pageX;
      let y = e.pageY;
      for (let i = 0; i < 1; i++) {
        let oDiv = document.createElement('div')
        oDiv.innerHTML = '❤'
        oDiv.className = 'animate__animated animate__fadeInUp'
        setTimeout(() => {
          oDiv.className = 'animate__animated animate__fadeOut'
        }, 400)
        oDiv.style.cssText = `position:absolute;font-size:3rem;color:red;left:${x - 15}px;top:${y - 120}px;`
        document.body.appendChild(oDiv)
        setTimeout(() => {
          if (document.querySelector('.animate__fadeOut')) {
            document.body.removeChild(oDiv)
          }
        }, 1500)
      }
    },
    // 随机数
    MathRandom(max, min) {
      return Math.floor(Math.random() * (max - min) + min)
    },
    // 制作考试题
    makeProblom() {
      this.wordList.forEach(ele => {
        let arr1 = ele.yyh.split('##').splice(0, 3)
        arr1.push(ele.cy)
        arr1.sort(() => Math.random() - 0.5)
        let arr3 = ele.hyy.split('##').splice(0, 3)
        arr3.push(ele.nr)
        arr3.sort(() => Math.random() - 0.5)
        let arr4 = ele.tyxy.split('##').splice(0, 3)
        arr4.push(ele.nr)
        arr4.sort(() => Math.random() - 0.5)
        let test1 = {
          type: '1',
          nr: ele.nr,
          cy: ele.cy,
          dcid: ele.id,
          ygfy: ele.ygfy,
          mgfy: ele.mgfy,
          options: arr1
        }
        let test3 = {
          type: '3',
          nr: ele.nr,
          cy: ele.cy,
          dcid: ele.id,
          ygfy: ele.ygfy,
          mgfy: ele.mgfy,
          options: arr3
        }
        let test4 = {
          type: '4',
          nr: ele.nr,
          cy: ele.cy,
          dcid: ele.id,
          ygfy: ele.ygfy,
          mgfy: ele.mgfy,
          options: arr4
        }
        this.submitData.problem.push(test1, test3, test4)
        if (!ele.lx) {
          let arr2 = ele.dccf + ',' + ele.grcfx
          let test2 = {
            type: '2',
            nr: ele.nr,
            cy: ele.cy,
            dcid: ele.id,
            ygfy: ele.ygfy,
            mgfy: ele.mgfy,
            options: arr2.split(',').splice(0, 8).sort(() => Math.random() - 0.5)
          }
          this.submitData.problem.push(test2)
        }
      })
      this.submitData.problem.sort(() => Math.random() - 0.5)
    },
    // 完成，返回
    toUnits() {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
      if (this.source === 'studyway') {
        this.$router.replace({ path: '/units?type=' + this.$route.query.type + '&bbid=' + this.$route.query.bbid + '&zid=' + this.$route.query.zid + '&bbmc=' + this.$route.query.bbmc  })
      } else if (this.source === 'wrong') {
        this.$router.replace({ path: '/wordbook/question' })
      } else if (this.source === 'collect') {
        this.$router.replace({ path: '/wordbook/collection' })
      }
    },
    // 发音
    play() {
      this.$refs.audio.play()
    },
    // 计时开始
    timeStart(time) {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
      this.time = time
      this.timer = setInterval(() => {
        this.time--
        if (this.time === 0) {
          if ((this.submitData.dyid + '' == this.$route.query.dyid) && (this.key == sessionStorage.getItem('key'))) {
            this.optionSelected = []
            clearInterval(this.timer)
            this.timer = null
            if (this.source === 'studyway') {
              if (this.currentProblem.type === '1') {
                this.submitData.list.find(ele => { if (ele.dcid === this.currentProblem.dcid) { return ele } }).yyh = 0
              } else if (this.currentProblem.type === '3') {
                this.submitData.list.find(ele => { if (ele.dcid === this.currentProblem.dcid) { return ele } }).hyy = 0
              } else if (this.currentProblem.type === '4') {
                this.submitData.list.find(ele => { if (ele.dcid === this.currentProblem.dcid) { return ele } }).tyxy = 0
              } else {
                this.submitData.list.find(ele => { if (ele.dcid === this.currentProblem.dcid) { return ele } }).kypc = 0
              }
            }
            if (this.source === 'studyway') {
              let obj = this.submitData.list.find(ele => { if (ele.dcid === this.currentProblem.dcid) { return ele } })
              if (obj && !obj.lx && (Object.keys(obj).length === 8)) {
                obj.sfzw = Number(obj.kypc && obj.tyxy && obj.yyh && obj.hyy)
                this.submitData.zwsl += obj.sfzw
                if (obj.sfzw && obj.sfsx) {
                  obj.dczt = 2
                } else if (obj.sfzw && !obj.sfsx) {
                  obj.dczt = 1
                } else if (obj.sfsx && (obj.kypc || obj.tyxy || obj.yyh || obj.hyy)) {
                  obj.dczt = 1
                } else {
                  obj.dczt = 0
                }
              } else if (obj && obj.lx && (Object.keys(obj).length === 7)) {
                obj.kypc = 1
                obj.sfzw = Number(obj.kypc && obj.tyxy && obj.yyh && obj.hyy)
                this.submitData.zwsl += obj.sfzw
                if (obj.sfzw && obj.sfsx) {
                  obj.dczt = 2
                } else if (obj.sfzw && !obj.sfsx) {
                  obj.dczt = 1
                } else if (obj.sfsx && (obj.kypc || obj.tyxy || obj.yyh || obj.hyy)) {
                  obj.dczt = 1
                } else {
                  obj.dczt = 0
                }
              }
              this.submitData.problemIndex = this.currentIndex
              this.submitData.xxsc = this.submitData.xxsc + parseInt((new Date().getTime() - this.submitData.startTime) / 1000)
              this.submitData.startTime = new Date().getTime()
              if (this.currentIndex + 1 !== this.submitData.problem.length) {
                // cjlx 任务false集训true
                saveMyBreakpoint({ cjid: JSON.parse(sessionStorage.getItem('jxInfo')).jxid, cjlx: true, ddxx: JSON.stringify(this.submitData), dyid: Number(this.$route.query.dyid), xsid: this.$store.state.userInfo.yhid }).then(res => {
                  this.nextItem()
                }).catch(error => {
                  this.$message.error({ message: '当前网络卡顿，请刷新后重试', duration: 0 })
                })
              } else {
                this.nextItem()
              }
            } else {
              this.nextItem()
            }
          } else {
            clearInterval(this.timer)
            this.timer = null
          }
        }
      }, 1000)
    },
    // 下一题
    nextItem() {
      if (this.key != sessionStorage.getItem('key')) {
        return false
      }
      if (this.currentIndex + 1 === this.submitData.problem.length) {
        if (this.source === 'studyway') {
          this.submitData.fs = parseInt(((this.submitData.zwsl + JSON.parse(sessionStorage.getItem('jxInfo')).zwsl) / JSON.parse(sessionStorage.getItem('jxInfo')).dcsl) * 100)
          delete this.submitData.problem
          delete this.submitData.Bingo
          delete this.submitData.startTime
          delete this.submitData.progress
          delete this.submitData.problemIndex
          delete this.submitData.zwsl
          this.submitData.list.forEach(ele => {
            if (ele.dczt === undefined) {
              ele.hyy = 1
              ele.kypc = 1
              ele.tyxy = 1
              ele.yyh = 1
              ele.dczt = 2
              ele.sfzw = 1
            }
          })
        this.submitData.bbid=this.$route.query.bbid
        this.submitData.bbmc=this.$route.query.bbmc
          submitWords(this.submitData).then(res => {
            if (res.success) {
              this.content = 'test'
            } else {
              this.$message.warning('考试结果提交失败,请刷新后重试')
            }
          }).catch(() => {
            this.$message.warning('考试结果提交失败,请刷新后重试')
          })
        } else {
          this.submitData.fs = parseInt(this.submitData.Bingo / this.submitData.problem.length * 100)
          this.content = 'test'
        }
      } else {
        this.currentIndex++
        this.currentProblem = this.submitData.problem[this.currentIndex]
        this.content = 'test' + this.currentProblem.type
        this.timeStart(20)
      }
    },
    // 选择题提交
    doTest(type, item, e) {
      if (this.clickStop) {
        return ''
      }
      clearInterval(this.timer)
      this.timer = null
      this.clickStop = true
      this.ansYes = type === '1' ? this.currentProblem.cy : this.currentProblem.nr
      if (item === this.ansYes) {
        this.submitData.Bingo++
        this.heartsFun(e)
      } else {
        this.ansNo = item
      }
      if (this.source === 'studyway') {
        if (type === '1') {
          this.submitData.list.find(ele => { if (ele.dcid === this.currentProblem.dcid) { return ele } }).yyh = Number(item === this.ansYes)
        } else if (type === '3') {
          this.submitData.list.find(ele => { if (ele.dcid === this.currentProblem.dcid) { return ele } }).hyy = Number(item === this.ansYes)
        } else {
          this.submitData.list.find(ele => { if (ele.dcid === this.currentProblem.dcid) { return ele } }).tyxy = Number(item === this.ansYes)
        }
      }
      if (this.source === 'studyway') {
        let obj = this.submitData.list.find(ele => { if (ele.dcid === this.currentProblem.dcid) { return ele } })
        if (obj && !obj.lx && (Object.keys(obj).length === 8)) {
          obj.sfzw = Number(obj.kypc && obj.tyxy && obj.yyh && obj.hyy)
          this.submitData.zwsl += obj.sfzw
          if (obj.sfzw && obj.sfsx) {
            obj.dczt = 2
          } else if (obj.sfzw && !obj.sfsx) {
            obj.dczt = 1
          } else if (obj.sfsx && (obj.kypc || obj.tyxy || obj.yyh || obj.hyy)) {
            obj.dczt = 1
          } else {
            obj.dczt = 0
          }
        } else if (obj && obj.lx && (Object.keys(obj).length === 7)) {
          obj.kypc = 1
          obj.sfzw = Number(obj.kypc && obj.tyxy && obj.yyh && obj.hyy)
          this.submitData.zwsl += obj.sfzw
          if (obj.sfzw && obj.sfsx) {
            obj.dczt = 2
          } else if (obj.sfzw && !obj.sfsx) {
            obj.dczt = 1
          } else if (obj.sfsx && (obj.kypc || obj.tyxy || obj.yyh || obj.hyy)) {
            obj.dczt = 1
          } else {
            obj.dczt = 0
          }
        }
        this.submitData.problemIndex = this.currentIndex
        this.submitData.xxsc = this.submitData.xxsc + parseInt((new Date().getTime() - this.submitData.startTime) / 1000)
        this.submitData.startTime = new Date().getTime()
        if (this.currentIndex + 1 !== this.submitData.problem.length) {
          // cjlx 任务false集训true
          saveMyBreakpoint({ cjid: JSON.parse(sessionStorage.getItem('jxInfo')).jxid, cjlx: true, ddxx: JSON.stringify(this.submitData), dyid: Number(this.$route.query.dyid), xsid: this.$store.state.userInfo.yhid }).then(res => {
            setTimeout(() => {
              this.ansNo = ''
              this.ansYes = ''
              this.nextItem()
              this.clickStop = false
            }, 500)
          }).catch(error => {
            this.$message.error({ message: '当前网络卡顿，请刷新后重试', duration: 0 })
          })
        } else {
          setTimeout(() => {
            this.ansNo = ''
            this.ansYes = ''
            this.nextItem()
            this.clickStop = false
          }, 1000)
        }
      } else {
        setTimeout(() => {
          this.ansNo = ''
          this.ansYes = ''
          this.nextItem()
          this.clickStop = false
        }, 1000)
      }
    },
    // 拼词题点击选项
    selected(data, index) {
      if (this.optionSelected.map((ele) => { return ele.index }).indexOf(index) === -1) {
        this.optionSelected.push({ txt: data, index: index })
      } else {
        this.optionSelected.splice(this.optionSelected.map((ele) => { return ele.index }).indexOf(index), 1)
      }
    },
    // 拼词题删除选项
    delAll() {
      this.optionSelected = []
    },
    // 拼词题提交答案
    submit(e) {
      if (this.clickStop) {
        return ''
      }
      this.clickStop = true
      if (this.optionSelected.length === 0) {
        this.$message.warning('请拼写成正确的单词')
        this.clickStop = false
      } else {
        clearInterval(this.timer)
        this.timer = null
        let word = ''
        this.optionSelected.forEach(ele => {
          word += ele.txt
        })
        if (word.toLowerCase() === this.currentProblem.nr.toLowerCase()) {
          this.submitData.Bingo++
          this.heartsFun(e)
          if (this.source === 'studyway') {
            this.submitData.list.find(ele => { if (ele.dcid === this.currentProblem.dcid) { return ele } }).kypc = Number(word.toLowerCase() === this.currentProblem.nr.toLowerCase())
          }
          this.dioAnswer = 'yes1'
          if (this.source === 'studyway') {
            let obj = this.submitData.list.find(ele => { if (ele.dcid === this.currentProblem.dcid) { return ele } })
            if (obj && !obj.lx && (Object.keys(obj).length === 8)) {
              obj.sfzw = Number(obj.kypc && obj.tyxy && obj.yyh && obj.hyy)
              this.submitData.zwsl += obj.sfzw
              if (obj.sfzw && obj.sfsx) {
                obj.dczt = 2
              } else if (obj.sfzw && !obj.sfsx) {
                obj.dczt = 1
              } else if (obj.sfsx && (obj.kypc || obj.tyxy || obj.yyh || obj.hyy)) {
                obj.dczt = 1
              } else {
                obj.dczt = 0
              }
            } else if (obj && obj.lx && (Object.keys(obj).length === 7)) {
              obj.kypc = 1
              obj.sfzw = Number(obj.kypc && obj.tyxy && obj.yyh && obj.hyy)
              this.submitData.zwsl += obj.sfzw
              if (obj.sfzw && obj.sfsx) {
                obj.dczt = 2
              } else if (obj.sfzw && !obj.sfsx) {
                obj.dczt = 1
              } else if (obj.sfsx && (obj.kypc || obj.tyxy || obj.yyh || obj.hyy)) {
                obj.dczt = 1
              } else {
                obj.dczt = 0
              }
            }
            this.submitData.problemIndex = this.currentIndex
            this.submitData.xxsc = this.submitData.xxsc + parseInt((new Date().getTime() - this.submitData.startTime) / 1000)
            this.submitData.startTime = new Date().getTime()
            if (this.currentIndex + 1 !== this.submitData.problem.length) {
              // cjlx 任务false集训true
              saveMyBreakpoint({ cjid: JSON.parse(sessionStorage.getItem('jxInfo')).jxid, cjlx: true, ddxx: JSON.stringify(this.submitData), dyid: Number(this.$route.query.dyid), xsid: this.$store.state.userInfo.yhid }).then(res => {
                this.$nextTick(() => {
                  this.dialogMsg = true
                  setTimeout(() => {
                    this.optionSelected = []
                    this.dioAnswer = ''
                    this.dialogMsg = false
                    this.clickStop = false
                    this.nextItem()
                  }, 500)
                })
              }).catch(error => {
                this.$message.error({ message: '当前网络卡顿，请刷新后重试', duration: 0 })
              })
            } else {
              this.$nextTick(() => {
                this.dialogMsg = true
                setTimeout(() => {
                  this.optionSelected = []
                  this.dioAnswer = ''
                  this.dialogMsg = false
                  this.clickStop = false
                  this.nextItem()
                }, 1000)
              })
            }
          } else {
            this.$nextTick(() => {
              this.dialogMsg = true
              setTimeout(() => {
                this.optionSelected = []
                this.dioAnswer = ''
                this.dialogMsg = false
                this.clickStop = false
                this.nextItem()
              }, 1000)
            })
          }

        } else {
          if (this.source === 'studyway') {
            this.submitData.list.find(ele => { if (ele.dcid === this.currentProblem.dcid) { return ele } }).kypc = Number(word === this.currentProblem.nr)
          }
          this.dioAnswer = 'no1'
          if (this.source === 'studyway') {
            let obj = this.submitData.list.find(ele => { if (ele.dcid === this.currentProblem.dcid) { return ele } })
            if (obj && !obj.lx && (Object.keys(obj).length === 8)) {
              obj.sfzw = Number(obj.kypc && obj.tyxy && obj.yyh && obj.hyy)
              this.submitData.zwsl += obj.sfzw
              if (obj.sfzw && obj.sfsx) {
                obj.dczt = 2
              } else if (obj.sfzw && !obj.sfsx) {
                obj.dczt = 1
              } else if (obj.sfsx && (obj.kypc || obj.tyxy || obj.yyh || obj.hyy)) {
                obj.dczt = 1
              } else {
                obj.dczt = 0
              }
            } else if (obj && obj.lx && (Object.keys(obj).length === 7)) {
              obj.kypc = 1
              obj.sfzw = Number(obj.kypc && obj.tyxy && obj.yyh && obj.hyy)
              this.submitData.zwsl += obj.sfzw
              if (obj.sfzw && obj.sfsx) {
                obj.dczt = 2
              } else if (obj.sfzw && !obj.sfsx) {
                obj.dczt = 1
              } else if (obj.sfsx && (obj.kypc || obj.tyxy || obj.yyh || obj.hyy)) {
                obj.dczt = 1
              } else {
                obj.dczt = 0
              }
            }
            this.submitData.problemIndex = this.currentIndex
            this.submitData.xxsc = this.submitData.xxsc + parseInt((new Date().getTime() - this.submitData.startTime) / 1000)
            this.submitData.startTime = new Date().getTime()
            if (this.currentIndex + 1 !== this.submitData.problem.length) {
              // cjlx 任务false集训true
              saveMyBreakpoint({ cjid: JSON.parse(sessionStorage.getItem('jxInfo')).jxid, cjlx: true, ddxx: JSON.stringify(this.submitData), dyid: Number(this.$route.query.dyid), xsid: this.$store.state.userInfo.yhid }).then(res => {
                this.$nextTick(() => {
                  this.dialogMsg = true
                  setTimeout(() => {
                    this.optionSelected = []
                    this.dioAnswer = ''
                    this.dialogMsg = false
                    this.clickStop = false
                    this.nextItem()
                  }, 500)
                })
              }).catch(error => {
                this.$message.error({ message: '当前网络卡顿，请刷新后重试', duration: 0 })
              })
            } else {
              this.$nextTick(() => {
                this.dialogMsg = true
                setTimeout(() => {
                  this.optionSelected = []
                  this.dioAnswer = ''
                  this.dialogMsg = false
                  this.clickStop = false
                  this.nextItem()
                }, 1000)
              })
            }
          } else {
            this.$nextTick(() => {
              this.dialogMsg = true
              setTimeout(() => {
                this.optionSelected = []
                this.dioAnswer = ''
                this.dialogMsg = false
                this.clickStop = false
                this.nextItem()
              }, 1000)
            })
          }
        }
      }
    }
  },
  watch: {
    currentIndex(newV, oldV) {
      if (this.content != 'test3') {
        this.$nextTick(() => {
          this.play()
        })
      }
    },
  }
}
</script>
<style lang="scss" scoped>
@media screen and (max-height: 700px) {
  #testPage {
    .unitsTitle {
      display: none;
    }
    .studyMain {
      position: absolute;
      width: 80%;
      height: 80%;
      padding: 1vw 5vw 2vw;
      top: 50%;
    }
    .test1 {
      .header {
        height: 10%;
        .title {
          font-size: 2vw;
        }
      }
      .wordaudio {
        .word {
          height: 100%;
          position: relative;
          font-size: 2.5vw;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inherit;
        }
      }
      .options {
        .option {
          height: 25%;
        }
        .answer {
          width: 80%;
          padding: 2.5% 0;
          font-size: 2.3vw;
        }
      }
    }
    .test4 {
      .header {
        height: 10%;
      }
      .wordaudio {
        height: 15%;
      }
      .options {
        .option {
          height: 25%;
        }
        .answer {
          width: 80%;
          padding: 2.5% 0;
          font-size: 2.3vw;
        }
      }
    }
    .test2 {
      .header {
        height: 13%;
        .title {
          font-size: 2vw;
        }
      }
      .options2 {
        .option2 {
          padding: 1.5vw;
          font-size: 3vw;
        }
      }
      .mean {
        height: 12%;
        font-size: 3.5vw;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        span {
          display: block;
          height: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 3.5vw;
        }
      }
      .answers2 {
        width: 70%;
        .answer2 {
          height: 6vw;
          line-height: 6vw;
          font-size: 2.4vw;
        }
      }
      .btns {
        bottom: 0;
      }
      .options2 {
        .del {
          font-size: 3vw;
          right: -5vw;
        }
      }
    }
  }
  #showall {
    font-size: 3vw;
  }
}
.showall {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  background: #5990ff;
  color: #fff;
  font-size: 2vw;
  padding: 5px 10px;
  border-radius: 20px;
}
.wordhidden {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.progress {
  height: 10%;
  width: 70%;
  margin: auto;
  position: relative;
  /deep/.el-progress {
    width: 100%;
  }
  .text {
    text-align: right;
    font-size: 1.5vw;
    position: absolute;
    right: -6vw;
    top: -5px;
    color: #5990ff;
  }
}
.dialogMsg {
  .dio {
    background: #fff;
    border-radius: 22px;
    padding-top: 20px;
    img {
      display: block;
      margin: 30px auto;
      margin-bottom: 50px;
    }
    .title {
      text-align: center;
      font-size: 30px;
      line-height: 30px;
      margin-bottom: 20px;
    }
    .dioWord {
      text-align: center;
      font-size: 28px;
      line-height: 28px;
      padding-bottom: 30px;
    }
  }
}
.testPage {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: url("../../../assets/images/student/learnwords.png") no-repeat;
  background-size: 100% 100%;
  .main {
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: 75%;
    background: url("../../../assets/images/student/unitBg.png") no-repeat;
    background-size: 100% 100%;
    padding: 5vw;
    padding-bottom: 4vw;
    .unitsTitle {
      width: 50%;
      display: inline-block;
      position: absolute;
      top: -10%;
      left: 50%;
      transform: translateX(-50%);
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
      .text {
        position: absolute;
        top: 0;
        width: 100%;
        text-align: center;
        color: #000;
        font-size: 1.7vw;
        padding-top: 0.5vw;
      }
    }
  }
  .congratulations {
    width: 34vw;
    height: 25vw;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -60%);
    .image {
      position: relative;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
      .text {
        position: absolute;
        bottom: 20%;
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 1.2rem;
        left: -1.5vw;
      }
      .span {
        position: absolute;
        color: #fff;
        font-size: 5.5vw;
        top: 61%;
        left: 45%;
        transform: translate(-50%, -50%);
        -webkit-text-stroke: 0.25vw #ff4545;
        text-shadow: 5px 5px 5px #333;
      }
    }
  }
  .practice {
    width: 13rem;
    height: 4rem;
    position: fixed;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -10%);
    cursor: pointer;
    .left {
      width: 13rem;
      height: 4rem;
      position: relative;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
      span {
        position: absolute;
        top: 50%;
        left: 50%;
        color: #fff;
        font-size: 1.3vw;
        transform: translate(-50%, -50%);
      }
    }
    .right {
      width: 13rem;
      height: 4rem;
      position: relative;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
      span {
        position: absolute;
        top: 50%;
        left: 50%;
        color: #fff;
        font-size: 1.3vw;
        transform: translate(-50%, -50%);
      }
    }
    .text {
      position: absolute;
      top: 0;
      text-align: center;
      color: #fff;
      height: 100%;
      width: 100%;
      font-size: 28px;
      line-height: 4rem;
    }
  }
  // 松鼠
  .squirrel {
    position: absolute;
    top: 23%;
    left: 15%;
    width: 8vw;
    height: 6.57vw;
    cursor: pointer;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  //   第一次集训
  .test1 {
    width: 100%;
    height: 100%;
    .header {
      width: 100%;
      height: 15%;
      position: relative;
      .title {
        width: 50%;
        text-align: center;
        margin: auto;
        font-size: 1.9vw;
        color: #333;
      }
      .time {
        position: absolute;
        right: 2vw;
        top: 0.3vw;
        font-size: 1.7vw;
        span {
          color: #ff4545;
          font-size: 1.8vw;
        }
      }
    }
    .wordaudio {
      width: 100%;
      height: 15%;
      text-align: center;
      .word {
        color: #333;
        // 文字撑开div
        width: fit-content;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        font-size: 2vw;
        position: relative;
        margin: auto;
        max-width: 90%;
        display: block;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        .img {
          position: absolute;
          right: 0;
          width: 4vw;
          height: 4vw;
          top: -10px;
          right: -6vw;
          cursor: pointer;
          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .options {
      width: 100%;
      height: 65%;
      // border: 1px solid red;
      .option {
        position: relative;
        height: 20%;
        margin-bottom: 1%;
        .answer {
          box-sizing: border-box;
          cursor: pointer;
          padding: 1.5% 0;
          width: 50%;
          text-align: center;
          font-size: 1.5vw;
          margin: auto;
          border: 2px solid #ffffff;
          border-radius: 10px;
          color: #003333;
          line-height: 1.5vw;
          position: relative;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          .icon {
            width: 3vw;
            height: 3vw;
            position: absolute;
            top: 0;
            right: -5vw;
            img {
              display: block;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .test2 {
    width: 100%;
    height: 100%;
    .header {
      width: 100%;
      height: 15%;
      position: relative;
      .title {
        width: 50%;
        text-align: center;
        margin: auto;
        font-size: 1.9vw;
        color: #333;
      }
      .time {
        position: absolute;
        right: 2vw;
        top: 0.3vw;
        font-size: 1.7vw;
        span {
          color: #ff4545;
          font-size: 1.8vw;
        }
      }
    }
    .mean {
      width: 100%;
      height: 14%;
      text-align: center;
      font-size: 1.9vw;
      line-height: 1.9vw;
      color: #333;
      span {
        max-width: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
    }
    .options2 {
      margin: auto;
      height: 16%;
      .center {
        width: fit-content;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        position: relative;
        margin: auto;
      }
      .del {
        font-size: 2vw;
        position: absolute;
        right: -3vw;
        display: none;
        i {
          color: #666;
          cursor: pointer;
        }
      }
      .option2 {
        font-size: 2vw;
        float: left;
        padding: 0.5vw;
        background: #5990ff;
        color: #fff;
        border-radius: 6px;
        line-height: 2vw;
        margin-right: 5px;
      }
    }
    .hr {
      width: 40%;
      height: 2px;
      background: #000;
      margin: 0 auto;
    }
    .answers2 {
      padding-top: 15px;
      width: 50%;
      height: 49%;
      margin: 0 auto;
      .answer2 {
        width: 22%;
        height: 4vw;
        line-height: 4vw;
        margin-left: 3%;
        font-size: 1.5vw;
        float: left;
        text-align: center;
        border: 2px solid #fff;
        border-radius: 6px;
        cursor: pointer;
        box-sizing: border-box;
        margin-bottom: 20px;
      }
      .answer2.selected {
        background: #5990ff;
        border: 1px solid #5990ff;
        color: #fff;
      }
    }
    .btns {
      position: absolute;
      bottom: -1vw;
      width: 15%;
      left: 50%;
      height: 10%;
      transform: translateX(-50%);
      cursor: pointer;
      img {
        display: block;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
      span {
        position: absolute;
        top: 48%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.6vw;
        color: #fff;
      }
    }
  }
  //   第一次集训
  .test4 {
    width: 100%;
    height: 100%;
    .header {
      width: 100%;
      height: 15%;
      position: relative;
      .title {
        width: 50%;
        text-align: center;
        margin: auto;
        font-size: 1.9vw;
        color: #333;
        max-width: 90%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
      .time {
        position: absolute;
        right: 2vw;
        top: 0.3vw;
        font-size: 1.7vw;
        span {
          color: #ff4545;
          font-size: 1.8vw;
        }
      }
    }
    .wordaudio {
      width: 100%;
      height: 20%;
      text-align: center;
      .img {
        margin: auto;
        width: 4vw;
        height: 4vw;
        cursor: pointer;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
    .options {
      width: 100%;
      height: 65%;
      .option {
        position: relative;
        height: 20%;
        margin-bottom: 1%;
        .answer {
          box-sizing: border-box;
          cursor: pointer;
          padding: 1.5% 0;
          width: 50%;
          text-align: center;
          font-size: 1.5vw;
          margin: auto;
          border: 2px solid #ffffff;
          border-radius: 10px;
          color: #003333;
          line-height: 1.5vw;
          position: relative;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          .icon {
            width: 3vw;
            height: 3vw;
            position: absolute;
            top: 0;
            right: -5vw;
            img {
              display: block;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.dialogMsg {
  .el-dialog {
    width: 400px;
    border-radius: 22px;
  }
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 18px 14px 18px;
    // background: #daffa5;
    background: #4cb2f1;
    border-radius: 22px;
  }
}
.testPage {
  .progress {
    .el-progress {
      width: 100%;
      margin: auto;
    }
    .el-progress-bar__inner {
      background: linear-gradient(251deg, #3862d9, #4eb7fb);
    }
    .el-progress-bar__outer {
      height: 16px !important;
      .el-progress-bar__innerText {
        display: none;
      }
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #87c2ef;
    border-radius: 20px;
  }
}
</style>